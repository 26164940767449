import React, { useState, useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Basic Swiper CSS
import "./styles.scss";
import Image from "./image.svg";
import { Autoplay } from "swiper/modules";

// SlideSection Component to render slides in a unique section
const SlideSection = ({ slides, id, heading }) => (
  <section className={`section slides slides-${id}`}>
    {heading && (
      <div className="row" style={{ textAlign: "center" }}>
        <h2>{heading}</h2>
      </div>
    )}
    <div className="row slider">
      <Swiper
        spaceBetween={24}
        slidesPerView={Math.min(2, slides.length)}
        loop={slides.length > 5}
        modules={[Autoplay]}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        breakpoints={{
          735: {
            slidesPerView: Math.min(5, slides.length),
          },
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <img
              src={slide.image}
              alt={`Slide ${index + 1}`}
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </section>
);

// ContentSection Component to render content sections in a unique section
const ContentSection = ({
  heading,
  text,
  CTA,
  id,
  image,
  settings,
  script,
  pageSettings,
}) => {
  const extractCustomAttributes = (customAttributes) => {
    const attributes = {};
    if (customAttributes) {
      const attrRegex = /(\b[a-zA-Z-]+)=["']([^"']+)["']/g;
      let match;
      while ((match = attrRegex.exec(customAttributes))) {
        attributes[match[1]] = match[2];
      }
    }
    return attributes;
  };

  const customAttributes = extractCustomAttributes(script?.customAttributes);
  return (
    <section
      className={`section content content-${id} ${settings?.style} ${
        settings?.textStyle
      } ${settings?.background ? `bg${settings?.background}` : ""}`}
    >
      <div className="row">
        <div
          className={`column ${!image ? "text-center" : ""}`}
          style={{ zIndex: 1 }}
        >
          {heading && settings?.style !== "CallToAction" && <h1>{heading}</h1>}
          {heading && settings?.style === "CallToAction" && <h2>{heading}</h2>}

          {text && !/^\s*<p><br><\/p>\s*$/.test(text) && (
            <div
              style={{ marginBottom: "2.8rem" }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(text),
              }}
            />
          )}
          {CTA?.label && (
            <a
              className={`btn ${CTA.size} ${CTA.style ? CTA.style : "primary"}`}
              {...customAttributes}
              href={CTA.url}
            >
              {CTA.label}
            </a>
          )}
        </div>
        {image && image?.url && (
          <div className="column">
            <img
              className="HeroImage"
              src={image?.url}
              style={{ maxWidth: image?.width + "%" || "100%" }}
              alt={image?.altText}
            />
          </div>
        )}
      </div>
    </section>
  );
};

const SlidesContentSection = ({
  slides,
  id,
  heading,
  direction,
  style,
  CTA,
  script,
}) => {
  const extractCustomAttributes = (customAttributes) => {
    const attributes = {};
    if (customAttributes) {
      const attrRegex = /(\b[a-zA-Z-]+)=["']([^"']+)["']/g;
      let match;
      while ((match = attrRegex.exec(customAttributes))) {
        attributes[match[1]] = match[2];
      }
    }
    return attributes;
  };

  const customAttributes = extractCustomAttributes(script?.customAttributes);
  return (
    <section
      className={`section slides slides-${id}`}
      style={{ padding: "9.6rem 0" }}
    >
      {heading && (
        <div className="row" style={{ padding: "0 16px", textAlign: "center" }}>
          <h2>{heading}</h2>
        </div>
      )}

      <div className="row slider">
        <Swiper
          spaceBetween={24}
          slidesPerView={Math.min(1, slides.length)} // Adjust slidesPerView as needed
          loop={slides.length > 3}
          modules={[Autoplay]}
          speed={20000}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
            reverseDirection: direction === "ltr",
          }}
          breakpoints={{
            735: {
              slidesPerView: Math.min(3, slides.length),
            },
          }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="slide-content">
                {slide.image && style === "Avatar" && (
                  <div className="avatar">
                    <img
                      src={slide.image}
                      alt={`Slide ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
                {slide.image && style !== "Avatar" && (
                  <img
                    src={slide.image}
                    alt={`Slide ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                )}
                {(slide.text || slide.heading) && (
                  <div className="SlideText">
                    {slide.heading && <h4>{slide.heading}</h4>}

                    {slide.text && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(slide.text),
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {CTA?.label && (
        <a
          className={`btn ${CTA.size} ${CTA.style ? CTA.style : "primary"}`}
          {...customAttributes}
          href={CTA.url}
        >
          {CTA.label}
        </a>
      )}
    </section>
  );
};

const ScriptSection = ({ id, script }) => {
  const scriptContainerRef = useRef(null);

  useEffect(() => {
    if (scriptContainerRef.current) {
      // Clear any previous script content
      scriptContainerRef.current.innerHTML = "";

      // Create a script element
      const scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";

      // Regex to match valid attributes and values
      const attributes = [
        ...script.matchAll(/(\b[a-zA-Z-]+)=["']([^"']+)["']/g),
      ];

      // Apply extracted attributes to the script element
      attributes.forEach(([_, attrName, attrValue]) => {
        if (/^[a-zA-Z][a-zA-Z0-9-_]*$/.test(attrName)) {
          scriptElement.setAttribute(attrName, attrValue);
        }
      });

      // Handle external `src` scripts
      if (script.includes("src=")) {
        const srcMatch = script.match(/src=["']([^"']+)["']/);
        if (srcMatch && srcMatch[1]) {
          scriptElement.src = srcMatch[1];
          scriptElement.async = true;
        }
      }

      // Handle inline scripts
      const inlineScriptMatch = script.match(
        /<script[^>]*>([\s\S]*?)<\/script>/
      );
      if (inlineScriptMatch && inlineScriptMatch[1]) {
        scriptElement.textContent = inlineScriptMatch[1];
      }

      // Append the script element to the container
      scriptContainerRef.current.appendChild(scriptElement);
    }
  }, [script]);

  return (
    <section className={`section script script-${id}`}>
      <div className="row" ref={scriptContainerRef}>
        {/* The script will be dynamically injected here */}
      </div>
    </section>
  );
};

const getYouTubeEmbedUrl = (url) => {
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(youtube\.com|youtu\.be)(\/(watch\?v=|embed\/|v\/|live\/)?(\w+))/;
  const match = url.match(youtubeRegex);
  return match ? `https://www.youtube.com/embed/${match[4]}` : url;
};

const VideoSection = ({ videoUrl, id, text, settings, CTA, script }) => {
  // Check if it's a YouTube URL and convert it to embed format if necessary
  const embedUrl = getYouTubeEmbedUrl(videoUrl);

  const extractCustomAttributes = (customAttributes) => {
    const attributes = {};
    if (customAttributes) {
      const attrRegex = /(\b[a-zA-Z-]+)=["']([^"']+)["']/g;
      let match;
      while ((match = attrRegex.exec(customAttributes))) {
        attributes[match[1]] = match[2];
      }
    }
    return attributes;
  };

  const customAttributes = extractCustomAttributes(script?.customAttributes);
  return (
    <section className={`section video ${settings?.textStyle} video-${id}`}>
      <div className="row">
        <div className="video-container">
          <div
            className="video-wrapper"
            style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
          >
            <iframe
              src={embedUrl}
              title={text || `Video ${id}`}
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: 0,
              }}
            />
          </div>
        </div>
      </div>
      {text && (
        <div className="row" style={{ textAlign: "center" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(text),
            }}
          />
        </div>
      )}
      {CTA?.label && (
        <a
          className={`btn ${CTA.size} ${CTA.style ? CTA.style : "primary"}`}
          {...customAttributes}
          href={CTA.url}
        >
          {CTA.label}
        </a>
      )}
    </section>
  );
};

// Main App Component
function App() {
  const [content, setContent] = useState({ sections: [] });
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [error, setError] = useState("");

  // useEffect(() => {
  //   fetch("/content.json")
  //     .then((response) => response.json())
  //     .then((data) => setContent(data))
  //     .catch((error) => console.error("Error fetching content:", error));
  // }, []);

  useEffect(() => {
    // Get the current domain from the browser's URL
    const domain = window.location.hostname; // e.g., "content.com" or "localhost"
    console.log("Domain:", domain);

    // Convert the domain into the format used for the JSON file (replace dots with hyphens)
    const formattedDomain = domain.replace(/\./g, "-"); // Converts content.com -> content-com
    const jsonFileName = `${formattedDomain}.json`; // Create file name content-com.json
    console.log("Formatted JSON File Name:", jsonFileName);

    // Handle case for localhost
    const isLocalhost = domain.includes("localhost");

    // If local, use "localhost.json", otherwise use formatted domain
    const fileToFetch = isLocalhost ? "localhost.json" : jsonFileName;

    // Fetch the corresponding JSON content for this domain
    fetch(`/pages/${fileToFetch}`) // Assuming pages folder is inside public
      .then((response) => {
        if (!response.ok) {
          throw new Error("Content not found");
        }
        return response.json();
      })
      .then((data) => {
        setContent(data);
        setLoading(false); // Finished loading content
      })
      .catch((error) => {
        setError("Error fetching content: " + error.message);
        setLoading(false); // Finished loading with error
      });
  }, []);

  // Recursive rendering based on section type
  const renderSection = (section, pageSettings) => {
    switch (section.type) {
      case "content":
        return (
          <ContentSection
            key={section.id}
            id={section.id} // Unique ID for each section
            heading={section.data.heading}
            text={section.data.text}
            CTA={section.data.CTA}
            image={section.data.image}
            script={section.data.script}
            settings={section.data.settings}
          />
        );
      case "slides":
        return (
          <SlideSection
            key={section.id}
            id={section.id} // Unique ID for each slide section
            slides={section.data.slides}
            heading={section.data.heading}
          />
        );
      case "slidesContent": // Handle new slidesContent section
        return (
          <SlidesContentSection
            key={section.id}
            id={section.id} // Unique ID for each slides content section
            slides={section.data.slides}
            heading={section.data.heading}
            style={section.data.style}
            direction={section.data.direction}
            CTA={section.data.CTA}
            script={section.data.script}
          />
        );
      case "video":
        return (
          <VideoSection
            key={section.id}
            id={section.id}
            videoUrl={section.data.videoUrl}
            text={section.data.text}
            settings={section.data.settings}
            CTA={section.data.CTA}
            script={section.data.script}
          />
        );
      case "script":
        return (
          <ScriptSection
            key={section.id}
            id={section.id}
            script={section.data.script}
          />
        );
      default:
        return null; // Add more cases if needed for other types
    }
  };

  return (
    <>
      <Helmet>
        <title>{content.pageSettings?.metaTitle}</title>
        <meta
          name="description"
          content={content.pageSettings?.metaDescription}
        />
        <meta name="keywords" content={content.pageSettings?.metaKeywords} />
        <meta property="og:title" content={content.pageSettings?.metaTitle} />
        <link
          rel="icon"
          type="image/png"
          href={content.pageSettings?.favicon}
          sizes="48x48"
        />
        <meta
          property="og:description"
          content={content.pageSettings?.metaDescription}
        />
        <meta property="og:image" content={Image} />
        <style>
          {`:root {
            --primaryColor: ${content.pageSettings?.primaryColor || "#617ce5"};
            --buttonColor: ${content.pageSettings?.buttonColor || "#617ce5"};
            --buttonHoverColor: ${
              content.pageSettings?.buttonHoverColor || "#617ce5"
            };
            --buttonTextColor: ${
              content.pageSettings?.buttonTextColor || "white"
            };
            --buttonSecondaryColor: ${
              content.pageSettings?.buttonSecondaryColor || "#617ce5"
            };
            --buttonSecondaryHoverColor: ${
              content.pageSettings?.buttonSecondaryHoverColor || "#617ce5"
            };
            --buttonSecondaryTextColor: ${
              content.pageSettings?.buttonSecondaryTextColor || "white"
            };
            --notificationColor: ${
              content.pageSettings?.notificationColor || "#AFBDF3"
            };
          }`}
        </style>
      </Helmet>

      <div className="Page">
        {content.pageSettings?.notification && (
          <div
            className="NotificationBanner"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content.pageSettings.notification),
            }}
          />
        )}
        {content.sections &&
          content.sections.length > 0 &&
          content.sections
            .sort((a, b) => a.order - b.order)
            .map((section) => renderSection(section))}
      </div>
    </>
  );
}

export default App;
